<template>
  <div>
    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-form
      v-else
      ref="formRef"
      @submit.prevent="handleFormSubmit"
    >


      <v-card
        title="Konto bearbeiten"
      >
        <v-card-title>Konto bearbeiten</v-card-title>
        <v-card-text>


          <v-row>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.mainData.vorname"
                label="Vorname*"
                type="text"
                outlined
                hide-details="auto"
                placeholder="Vorname"
                :error-messages="errorMessages.vorname"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.mainData.name"
                label="Name*"
                type="text"
                outlined
                hide-details="auto"
                placeholder="Name"
                :error-messages="errorMessages.name"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field
                v-model="form.mainData.firma"
                label="Firma*"
                placeholder="Firma"
                type="text"
                outlined
                hide-details="auto"
                :error-messages="errorMessages.firma"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field
                v-model="form.mainData.email"
                :prepend-inner-icon="icons.mdiEmailOutline"
                label="E-Mail*"
                type="email"
                outlined
                hide-details="auto"
                placeholder="E-Mail"
                :error="(errorMessages.email != undefined)"
                :error-messages="errorMessages.email"
                :rules="[validators.required, validators.emailValidator]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.mainData.passwort"
                :prepend-inner-icon="icons.mdiLockOutline"
                label="Passwort*"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                placeholder="Passwort"
                hide-details="auto"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                :error="errorMessages.passwort != undefined"
                :error-messages="errorMessages.passwort"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.mainData.passwort_w"
                :prepend-inner-icon="icons.mdiLockOutline"
                label="Passwort-Wiederholung*"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                placeholder="Passwort-Wiederholung"
                hide-details="auto"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                :error="errorMessages.passwort_w != undefined"
                :error-messages="errorMessages.passwort_w"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="8">
              <v-text-field
                v-model="form.mainData.strasse"
                label="Strasse*"
                type="text"
                outlined
                hide-details="auto"
                placeholder="Strasse"
                :error-messages="errorMessages.strasse"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.mainData.hausNr"
                label="Nr.*"
                type="text"
                outlined
                hide-details="auto"
                placeholder="Nr."
                :error-messages="errorMessages.hausNr"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.mainData.plz"
                label="PLZ*"
                type="text"
                outlined
                hide-details="auto"
                placeholder="PLZ"
                :error-messages="errorMessages.plz"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.mainData.ort"
                label="Ort*"
                type="text"
                outlined
                hide-details="auto"
                placeholder="Ort"
                :error-messages="errorMessages.ort"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-select
                v-model="form.mainData.land"
                hide-details="auto"
                outlined
                :items="countries"
                item-text="value"
                item-value="value"
                label="Land*"
                placeholder="Land"
                :error-messages="errorMessages.land"
                :rules="[validators.required]"
              ></v-select>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field
                v-model="form.mainData.emailAllgemein"
                label="E-Mail Allgemein (info@)*"
                type="text"
                outlined
                hide-details="auto"
                placeholder="E-Mail Allgemein (info@)"
                :error-messages="errorMessages.emailAllgemein"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card
        class="mt-5"
        title="Weitere Daten"
      >
        <v-card-title>Weitere Daten</v-card-title>
        <v-card-text>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.mainData.webseite"
                label="Webseite (URL)"
                type="text"
                outlined
                hide-details="auto"
                placeholder="Webseite (URL)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.mainData.telefon"
                label="Telefon"
                placeholder="Telefon"
                type="text"
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.mainData.steuernummer"
                label="Steuernummer"
                placeholder="Steuernummer"
                type="text"
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.mainData.ustId"
                label="USt.-ID"
                placeholder="USt.-ID"
                type="text"
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

    <v-row class="mt-5">
      <v-col cols="12" md="12">
        <v-btn color="accent" type="submit">
          Speichern
        </v-btn>
      </v-col>
    </v-row>
    </v-form>
  </div>
</template>

<script>
import {onUnmounted, onMounted, ref, getCurrentInstance, nextTick} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import themeConfig from '@themeConfig'

import {
  mdiEmailOutline,
  mdiLockOutline,
  mdiEyeOffOutline,
  mdiEyeOutline
} from '@mdi/js'

import userStoreModule from '@/views/account/userStoreModule'
import axios from "axios";
import {
  alphaValidator,
  checkboxValidator,
  emailValidator,
  passwordValidator,
  required
} from "@core/utils/validation";

export default {
  components: {

  },
  setup() {
    const loading = ref(true)
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const formRef = ref(null)

    const form = ref({})
    form.value = {
      mainData: {
        vorname: '',
        name: '',
        firma: '',
        email: '',
        passwort: '',
        passwort_w: '',
        strasse : '',
        hausNr : '',
        plz : '',
        ort : '',
        land : '',
        emailAllgemein : '',
        webseite : '',
        telefon : '',
        steuernummer : '',
        ustId : '',
      }
    };

    const isPasswordVisible = ref(false)
    const errorMessages = ref([])

    const countries = ref([
      {value: "Deutschland"},
      {value: "Österreich"},
      {value: "Schweiz"},
    ])

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    onMounted(()=>{
      axios.post('/api/verwaltung/benutzerdaten/', {
        aktion: 'editUserData'
      })
        .then((response) => {
          if (response.data.valid === true) {
            form.value.mainData = response.data.user;
          } else {
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
            errorMessages.value = response.data.errors;
          }
          loading.value = false;
        })
        .catch((error) => {
          console.log(error)
          errorMessages.value = error
          loading.value = true;
        })
    })

    const handleFormSubmit = () => {
      const isFormValid = formRef.value.validate()
      if(!isFormValid)  {
        nextTick(() => {
          const firstErrorField = document.querySelector('.error--text');
          if (firstErrorField) {
            firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
        return
      }
      loading.value = true;

      const fd = new FormData();
      fd.append('aktion','editUserDataCheck');

      Object.keys(form.value.mainData).forEach(key => {
        fd.append(`mainData[${key}]`, form.value.mainData[key]);
      });

      axios
        .post('/api/verwaltung/benutzerdaten/',fd)
        .then(response => {
          if(response.data.valid === true){
            form.value.mainData.passwort = "";
            form.value.mainData.passwort_w = "";
            vm.$toastr.s(response.data.message.text, response.data.message.title);
          }
          else{
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
            errorMessages.value = response.data.errors;
          }
          loading.value = false;
        })
        .catch(error => {
          vm.$toastr.e(error,error);
          errorMessages.value = error
          loading.value = false;
        })
    }

    return {
      handleFormSubmit,
      loading,
      formRef,
      form,
      errorMessages,
      countries,
      isPasswordVisible,
      icons: {
        mdiEmailOutline,
        mdiLockOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      validators: {
        required,
        emailValidator,
        passwordValidator,
        checkboxValidator,
        alphaValidator,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

#foto{
  display:none
}
.uploadImage{
  cursor: pointer;
}

</style>
